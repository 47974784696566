<template>
  <v-dialog
    :value="value"
    scrollable
    persistent
    :overlay="false"
    max-width="800"
    transition="slide-y-transition"
  >
    <v-card>
      <v-card-title class="pos-r">
        {{ localData.mode === 'new' ? '新增通道' : '編輯通道' }}

        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="Secondary100"
          fab
          x-small
          class="b-rad-12"
          @click="close"
        >
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <SizeBox height="48" />
        <v-form ref="form">
          <v-row>
            <v-col cols="6">
              <v-text-field
                rounded
                label="名稱"
                v-model="form.name"
                :rules="[$rules.required]"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                rounded
                label="代號"
                v-model="form.code"
                :rules="[$rules.required]"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-select
                rounded
                label="類型"
                :items="channelTypeOptions"
                v-model="form.collect_channel_type_id"
                :rules="[$rules.required]"
                outlined
              ></v-select>
            </v-col>

            <v-col cols="12">
              <v-text-field
                rounded
                label="訂單有效秒數"
                hint="請輸入整數"
                v-model="form.validity_seconds"
                @change="handleValiditySeconds"
                type="number"
                :rules="[$rules.required]"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-switch
                label="訂單狀態"
                v-model="form.status"
                type="number"
                outlined
                dense
                hide-details
                inset
              ></v-switch>
            </v-col>

            <v-col cols="12">
              <v-switch
                label="撞單風控"
                v-model="form.is_avoid_duplicate"
                type="number"
                outlined
                dense
                hide-details
                inset
              ></v-switch>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn rounded color="Secondary100" depressed large @click="close">
          取消
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn rounded color="primary" dark depressed large @click="submit">
          {{ localData.mode === 'new' ? '新增通道' : '保存' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import schema from './schema';
import { addCollectChannel, editCollectChannel } from '@/api/collectChannels';

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    passData: {
      type: Object,
      default: () => ({
        /**
         * mode: String, // new, edit
         */
      })
    },
    channleTypes: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      localData: {},
      form: {
        id: '',
        collect_channel_type_id: '',
        gateway_url: '',
        sync_notify_url: '',
        async_notify_url: '',
        name: '',
        code: '',
        is_avoid_duplicate: true,
        status: true,
        validity_seconds: 0
      }
    };
  },

  computed: {
    readonly() {
      return this.localData.mode === 'view';
    },
    channelTypeOptions() {
      return this.channleTypes.map(({ id, name, code }) => ({
        text: name,
        value: id,
        code
      }));
    }
  },

  watch: {
    value(v) {
      v ? this.init() : this.reset();
    }
  },

  methods: {
    init() {
      this.$set(this, 'localData', { ...schema, ...this.passData });
      Object.keys(this.form).forEach((key) => {
        typeof this.passData[key] !== 'undefined' &&
          (this.form[key] = this.passData[key]);
      });
      this.$refs.form && this.$refs.form.resetValidation();
    },
    reset() {
      this.$set(this, 'localData', {});
      this.$refs.form && this.$refs.form.reset();
    },
    close() {
      this.$emit('close');
    },
    handleValiditySeconds(v) {
      if (v < 0) {
        this.$nextTick(() => {
          this.form.validity_seconds = 0;
          return;
        });
      }
      this.form.validity_seconds = Math.round(Number(v));
    },
    async submit() {
      if (!this.$refs.form.validate()) return;

      this.loading = true;
      const isNew = this.localData.mode === 'new';
      const firedApi = isNew ? addCollectChannel : editCollectChannel;
      const passdata = { ...this.form };

      if (isNew) {
        delete passdata.id;
      }

      const Channel = await firedApi(passdata);
      if (!Channel.error) {
        this.$emit('save');
        this.$toast('保存成功');
        this.close();
      } else {
        this.$toast.error('保存失敗');
      }

      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
