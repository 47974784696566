export default {
  id: '',
  collect_channel_type: null,
  collect_rule: null,
  gateway_url: '',
  sync_notify_url: '',
  async_notify_url: '',
  name: '',
  code: '',
  is_avoid_duplicate: false,
  status: false,
  validity_seconds: 0,
  created_at: '',
  updated_at: ''
}