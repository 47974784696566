<template>
  <v-dialog
    :value="value"
    scrollable
    persistent
    :overlay="false"
    max-width="800"
    transition="slide-y-transition"
  >
    <v-card min-height="500">
      <v-card-title class="pos-r">
        費率
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="Secondary100"
          fab
          x-small
          class="b-rad-12"
          @click="close"
        >
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <SizeBox height="48" />
        <v-form ref="form">
          <v-text-field
            rounded
            label="費率(%)"
            :value="fee"
            @change="handleInput"
            type="number"
            :rules="[checkFloat]"
            max="100"
            min="0"
            outlined
          ></v-text-field>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn
          rounded
          color="Secondary100"
          depressed
          large
          @click="close"
          :disabled="loading"
        >
          取消
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="primary"
          dark
          depressed
          large
          :loading="loading"
          @click="submit"
        >
          保存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { editFeePercent } from '@/api/collectChannels';

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    passData: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      loading: false,
      fee: 0
    };
  },

  watch: {
    value(v) {
      v ? this.init() : this.reset();
    }
  },

  methods: {
    init() {
      this.handleInput(this.passData.fee_percent);
    },
    reset() {
      //
    },
    close() {
      this.$emit('close');
    },
    handleInput(v) {
      this.$nextTick(() => {
        if (Number(v) > 100) {
          this.fee = 100;
        } else if (Number(v) < 0) {
          this.fee = 0;
        } else {
          this.fee = Number(v);
        }
      });
    },

    checkFloat(v) {
      const float = `${v}`.split('.')[1];

      if (float) {
        return float.length <= 2 ? true : '小數點後至多兩位';
      }

      return true;
    },
    async submit() {
      if (!this.$refs.form.validate()) return;

      this.loading = true;

      const { error } = await editFeePercent({
        ...this.passData,
        fee_percent: this.fee
      });
      if (!error) {
        this.$emit('save');
        this.$toast('保存成功');
        this.close();
      } else {
        this.$toast.error('保存失敗');
      }

      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
