<template>
  <!-- eslint-disable  -->
  <div>
    <v-card flat class="w100p">
      <!--  -->
    </v-card>
    <div class="d-sm-flex mb-3 text-Heading6 Medium d-none">
      {{ $store.getters['app/getRouteTitle'] }}
      <v-spacer></v-spacer>
    </div>

    <v-card
      outlined
      :color="$vuetify.breakpoint.smAndDown ? 'transparent' : null"
    >
      <v-card-text class="d-flex align-center pb-0 pb-sm-4">
        <v-spacer></v-spacer>

        <v-btn
          rounded
          color="primary"
          dark
          depressed
          @click="openForm('Info', null, 'new')"
          :disabled="!$getGrantValue('collect_channel_settings.add')"
        >
          新增通道
        </v-btn>
      </v-card-text>

      <DataTable
        :list="result.list"
        :total="result.total"
        :fields="headers"
        :loading="isLoading"
        :replace="[
          'collect_channel_type',
          'status',
          'is_avoid_duplicate',
          'actions'
        ]"
        @get-list="fetchData"
      >
        <template #item.collect_channel_type="{ item }">
          {{ item.collect_channel_type && item.collect_channel_type.name }}
        </template>
        <template #item.status="{ item }">
          <div class="flex-center">
            <v-switch
              color="Primary"
              :input-value="item.status"
              @click="changeStatus(item)"
              :disabled="
                item.isStatusChanging ||
                !$getGrantValue('collect_channel_settings.status')
              "
              inset
            ></v-switch>
          </div>
        </template>
        <template #item.is_avoid_duplicate="{ item }">
          <div class="flex-center">
            <v-switch
              color="Primary"
              :input-value="item.is_avoid_duplicate"
              @click="changeAvoid(item)"
              :disabled="
                item.isAvoidChanging ||
                !$getGrantValue('collect_channel_settings.avoid_duplicate')
              "
              inset
            ></v-switch>
          </div>
        </template>
        <template #item.actions="{ item }">
          <div class="flex-center">
            <v-menu>
              <template #activator="{ on }">
                <v-btn
                  v-on="on"
                  color="Secondary100"
                  rounded
                  :small="$vuetify.breakpoint.smAndDown"
                >
                  操作
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list rounded>
                <v-list-item
                  v-for="(key, i) in item.actions"
                  :key="`key:${item.id} ${i}`"
                  @click="
                    key.action && handleAction((vm) => key.action(vm, item))
                  "
                  :to="key.to ? key.to(item) : null"
                  :disabled="key.grant ? !$getGrantValue(key.grant) : false"
                >
                  <v-icon v-if="!!key.icon" size="18" class="mr-1">
                    {{ key.icon }}
                  </v-icon>
                  {{ key.label }}
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </DataTable>
    </v-card>

    <InfoForm
      :value="showingForm === 'Info'"
      @close="showingForm = null"
      @save="fetchData"
      :passData="passData"
      :channleTypes="channleTypes"
    />

    <WithdrawRiskForm
      :value="showingForm === 'WithdrawRisk'"
      @close="showingForm = null"
      @save="fetchData"
      :passData="passData"
    />

    <FeePercentFrom
      :value="showingForm === 'FeePercent'"
      @close="showingForm = null"
      @save="fetchData"
      :passData="passData"
    />
    <FunctionFab
      @info="isInfoOpen = true"
      @refresh="fetchData()"
      @open-filter="isFilterOpen = true"
      :no-filter="!filterOptions.length"
      :no-info="!Object.entries(result.header_info).length"
    />

    <FunctionFab
      @info="isInfoOpen = true"
      @refresh="fetchData()"
      @open-filter="isFilterOpen = true"
      :no-filter="!filterOptions.length"
      :no-info="!Object.entries(result.header_info).length"
    >
      <v-list-item
        v-if="$getGrantValue('collect_channel_settings.add')"
        @click="openForm('Info', null, 'new')"
      >
        <v-icon>mdi-plus</v-icon>
        <span class="text-no-wrap">新增通道</span>
      </v-list-item>
    </FunctionFab>
  </div>
</template>

<script>
import {
  getCollectChannelTypes,
  getCollectChannels,
  editCollectChannelStatus,
  editCollectChannelIsAvoidDuplicate,
  removeCollectChannel
} from '@/api/collectChannels';

import InfoForm from '@/components/channel/list/InfoForm.vue';
import WithdrawRiskForm from '@/components/channel/list/CollectRuleForm.vue';
import FeePercentFrom from '@/components/channel/list/FeePercentFrom.vue';

const actions = [
  {
    label: '編輯',
    icon: 'mdi-pencil-outline',
    grant: 'collect_channel_settings.edit',
    action: (vm, v) => {
      vm.openForm('Info', v, 'edit');
    }
  },
  {
    label: '子帳戶',
    icon: 'mdi-cog-outline',
    action: (vm, v) => {},
    to: (v) => ({
      name: '子帳戶列表',
      query: { channelId: v.id, name: v.name }
    })
  },
  {
    label: '風控',
    icon: 'mdi-transit-detour',
    grant: 'collect_channel_settings.rule_setting',
    action: (vm, v) => {
      vm.openForm('WithdrawRisk', v.collect_rule);
    }
  },
  {
    label: '費率',
    icon: 'mdi-currency-usd',
    grant: 'collect_channel_settings.fee_percent_setting',
    action: (vm, v) => {
      vm.openForm('FeePercent', v);
    }
  },
  {
    label: '刪除',
    icon: '',
    grant: 'collect_channel_settings.remove',
    action: (vm, v) => {
      vm.$confirm({
        title: '刪除',
        content: '刪除之後，資料將無法復原，您確定要刪除？',
        btn: {
          color: 'error',
          text: '刪除'
        },
        callback: async () => {
          await removeCollectChannel({ id: v.id });
          vm.$toast('刪除成功');
          vm.fetchData();
        }
      });
    }
  }
];

export default {
  components: {
    InfoForm,
    WithdrawRiskForm,
    FeePercentFrom
  },
  data() {
    return {
      ...this.$clone(this.$commonSchema),
      headers: [
        { text: '名稱', value: 'name', align: 'center' },
        { text: '代碼', value: 'code', align: 'center' },
        {
          text: '通道類型',
          value: 'collect_channel_type',
          align: 'center'
        },
        { text: '通道狀態', value: 'status', align: 'center' },
        {
          text: '撞單風控',
          value: 'is_avoid_duplicate',
          align: 'center'
        },
        { text: '費率', value: 'fee_percent', align: 'center' },
        { text: '操作', value: 'actions', align: 'center' }
      ],
      channleTypes: []
    };
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData(condition = this.condition) {
      this.isLoading = true;
      this.condition = condition;
      const types = await getCollectChannelTypes();
      this.channleTypes = types;
      const Channels = await getCollectChannels({
        ...this.filter,
        ...condition
      });
      if (!Channels.error) {
        this.result.total = Channels.total;
        this.result.page = Channels.page;
        this.$set(
          this.result,
          'list',
          Channels.items.map((item) => ({
            ...item,
            actions
          }))
        );
      }

      this.isLoading = false;
    },
    openForm(name, data = {}, mode = '') {
      this.showingForm = name;

      this.$set(this, 'passData', {
        mode,
        ...data
      });
    },
    handleAction(fn) {
      fn(this);
    },
    async changeStatus(item) {
      item.isStatusChanging = true;
      const { error } = await editCollectChannelStatus({
        id: item.id,
        status: !item.status
      });

      if (error) {
        return;
      } else {
        item.status = !item.status;
      }

      item.isStatusChanging = false;

      return error;
    },
    async changeAvoid(item) {
      item.isAvoidChanging = true;
      const { error } = await editCollectChannelIsAvoidDuplicate({
        id: item.id,
        is_avoid_duplicate: !item.is_avoid_duplicate
      });

      if (error) {
        return;
      } else {
        item.is_avoid_duplicate = !item.is_avoid_duplicate;
      }

      item.isAvoidChanging = false;

      return error;
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
